import React, { useState, useContext, useEffect } from "react";
import "./Referencia.css";
import { FormContext } from "./FormContext";
import { useNavigate } from "react-router-dom";
import FormHeader from "./FormHeader";
import FormFooter from "./FormFooter";
import Modal from "./../../components/Modal/Modal";
import { toast } from "react-toastify";

import { useFormStateValidation } from "./FormStateValidation";

export const Referencias = () => {
  useFormStateValidation();
  
  const { formState, updateForm } = useContext(FormContext);
  const navigate = useNavigate();
  const [anexos, setAnexos] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  useEffect(() => {
    const fetchForm = () => {
      formState.anexos
        ? setAnexos(formState.anexos)
        : setAnexos([]);
    };
    fetchForm();
  }, [formState.anexos]);

  const anexarFoto = () => {
    if (anexos.length >= 5) {
      toast.error("Você só pode anexar até 5 imagens");
      return;
    }

    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/jpeg, image/png";
    input.multiple = true;
    input.click();

    input.addEventListener("change", async (event) => {
      const files = event.target.files;
      if (files.length > 5) {
        toast.error("Você só pode selecionar até 5 imagens");
        return;
      }

      const promises = Array.from(files).map((file) => {
        if (!["image/jpeg", "image/png"].includes(file.type)) {
          toast.error("Apenas arquivos JPG e PNG são permitidos");
          return Promise.reject(new Error("Tipo de arquivo inválido"));
        }

        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = (e) => {
            const img = new Image();
            img.onload = () => {
              let width = img.width;
              let height = img.height;
              const maxWidth = 1920;
              const maxHeight = 1080;

              if (width > maxWidth || height > maxHeight) {
                const ratio = Math.min(maxWidth / width, maxHeight / height);
                width = width * ratio;
                height = height * ratio;
              }

              const canvas = document.createElement("canvas");
              canvas.width = width;
              canvas.height = height;
              const ctx = canvas.getContext("2d");
              ctx.drawImage(img, 0, 0, width, height);
              resolve(canvas.toDataURL("image/jpeg"));
            };
            img.onerror = reject;
            img.src = e.target.result;
          };
          reader.onerror = reject;
          reader.readAsDataURL(file);
        });
      });

      try {
        const imagesBase64 = await Promise.all(promises);

        if (anexos.length + imagesBase64.length > 5) {
          toast.error("Você só pode anexar até 5 imagens");
          return;
        }
        setAnexos([...anexos, ...imagesBase64]);
      } catch (error) {
        console.error("Erro ao processar imagens:", error);
      }
    });
  };

  const removeAnexo = (indexToRemove) => {
    setAnexos(anexos.filter((_, index) => index !== indexToRemove));
  };

  const openModal = (imageSrc) => {
    setSelectedImage(imageSrc);
    setModalVisible(true);
  };

  const handleSubmit = () => {
    updateForm({
      ...formState,
      anexos,
    });
    navigate("../detalhes");
  };

  return (
    <div>
      <FormHeader
        rightText={"passo 9/10"}
        title={"Me envie as referências que você tem para a sua tatuagem"}
      />
      <div className="body-referencia">
        <p className="text-wrapper-2">Você pode adicionar até 5 imagens</p>
        {anexos.length > 0 && (
          <div className="frame-1">
            <div className="frame-2">
              {anexos.map((anexo, index) => (
                <div className="image-container" key={index}>
                  <img
                    className="image-square"
                    src={anexo}
                    alt={`Referência ${index + 1}`}
                    onClick={() => openModal(anexo)}
                  />
                  <div
                    className="delete-icon"
                    onClick={() => removeAnexo(index)}
                  >
                    <img src={"/img/form/remove.svg"} alt="Delete icon" />
                  </div>
                </div>
              ))}
            </div>
            <div className="text-wrapper-3">{`${anexos.length} imagens adicionadas`}</div>
          </div>
        )}
        {anexos.length < 5 && (
          <div className="frame-3" onClick={anexarFoto}>
            <img
              className="add-photo-alternate"
              alt="Add icon alternate"
              src="/img/form/referencia/add-photo-alternate.png"
            />
            <p className="text-wrapper-3">
              Clique aqui para enviar suas referências
            </p>
          </div>
        )}
      </div>
      <FormFooter onClick={handleSubmit} type={"active"} pular={anexos?.length > 0 ? false:true} />
      {modalVisible && (
        <Modal show={modalVisible} onClose={() => setModalVisible(false)}>
          <img
            src={selectedImage}
            alt="Imagem em tamanho original"
            className="image-modal-referencia"
          />
        </Modal>
      )}
    </div>
  );
};

export default Referencias;
