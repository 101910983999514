import React, { useState, useContext, useEffect } from "react";
import { Slide } from "../../components/Slide/Slide";
import "./Tamanho.css";
import { FormContext } from "./FormContext";
import { useNavigate } from "react-router-dom";
import FormHeader from "./FormHeader";
import FormFooter from "./FormFooter";
import { useFormStateValidation } from "./FormStateValidation";

const tamanhos = [
  {
    name: "Micro",
    description: "Tampa de garrafa pet (aprox. 3cm)",
    img: "/img/form/tamanho/tampa.png",
  },
  {
    name: "Pequena",
    description: "Cartão (aprox. 8cm)",
    img: "/img/form/tamanho/cartao.png",
  },
  {
    name: "Média",
    description: "Caneta (aprox. 15cm)",
    img: "/img/form/tamanho/caneta.png",
  },
  {
    name: "Grande",
    description: "Palma da mão (aprox. 22cm)",
    img: "/img/form/tamanho/mao.png",
  },
];

export const Tamanho = () => {
  useFormStateValidation();
  const { formState, updateForm } = useContext(FormContext);
  const navigate = useNavigate();
  const [tamanhoSelecionado, setTamanhoSelecionado] = useState(null);

  useEffect(() => {
    const fetchForm = () => {
      formState.tamanho
        ? setTamanhoSelecionado(formState.tamanho)
        : setTamanhoSelecionado(tamanhos[0]);
    };
    fetchForm();
    // eslint-disable-next-line
  }, [formState.local]);

  useEffect(() => {
    let touchStartX = 0;
    let touchCurrentX = 0;

    const handleTouchStart = (e) => {
      touchStartX = e.touches[0].clientX;
    };

    const handleTouchMove = (e) => {
      touchCurrentX = e.touches[0].clientX;
      const swipeDistance = touchCurrentX - touchStartX;

      if (swipeDistance > 0) {
        // Evita o comportamento de voltar na navegação
        e.preventDefault();
      }
    };

    document.addEventListener("touchstart", handleTouchStart);
    document.addEventListener("touchmove", handleTouchMove);

    return () => {
      document.removeEventListener("touchstart", handleTouchStart);
      document.removeEventListener("touchmove", handleTouchMove);
    };
  }, []);

  const handleSubmit = () => {
    updateForm({
      ...formState,
      tamanho: tamanhoSelecionado,
    });
    navigate("../foto");
  };

  return (
    <div>
      <FormHeader rightText={"passo 5/10"} title={"Qual o tamanho aproximado?"} />
      
      <div className="body-tamanho">
        <div className="text-wrapper-3">Local escolhido: {formState?.local?.name}</div>
        <div className="frame-3">
          <div className="image-wrapper">
            <img className="image" alt="foto tamanho" src={tamanhoSelecionado?.img} />
          </div>
          <p className="p">{tamanhoSelecionado?.description}</p>
        </div>
        <div className="frame-3">
          <div className="text-wrapper-4">Tamanho</div>
          <div className="slider">
            <div className="overlap-group">
              <div className="slider-base" />
              <Slide className="handle-instance" tamanhos={tamanhos} onChange={setTamanhoSelecionado} />
            </div>
          </div>
        </div>
      </div>
      <FormFooter onClick={handleSubmit} type={tamanhoSelecionado ? "active" : "inactive"} />
    </div>
  );
};

export default Tamanho;
