import React, { useContext, useEffect, useState } from "react";
// import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import "./Form.css";
import { FormContext } from "./FormContext";
import { getArtistAlias } from "../../../api/api";
import { toast } from "react-toastify";

function Form() {
  const navigate = useNavigate();
  const { formState, updateForm, resetForm } = useContext(FormContext);
  const [artist, setArtist] = useState(null);
  const { artistAlias } = useParams();
  const [mensagemPadrao, setMensagemPadrao] = useState("");

  useEffect(() => {
    const fetchArtist = async () => {
      try {
        const data = await getArtistAlias(artistAlias);
        if(data.pixelid){
          addFacebookPixel(data.pixelid);
          addNoScriptPixel(data.pixelid)
        }
        setArtist(data);
        updateForm({ artista: data });
        setMensagemPadrao(`Opa!
        
Tudo certo?
        
Aqui é o ${data.nickname} e desde já agradeço por confiar no meu trabalho.
        
Para transferir a ideia que está na sua cabeça para a sua pele eu preciso de algumas informações.
        
Vamos juntos?`);
      } catch (error) {
        console.error(error);
        if (error.response) {
          // O pedido foi feito e o servidor respondeu com um status fora do intervalo de 2xx
          toast.error(error.response.data.msg);
          const timer = setTimeout(() => {
            navigate("/login");
          }, 3000);
          return () => clearTimeout(timer);
        } else if (error.request) {
          // O pedido foi feito, mas nenhuma resposta foi recebida
          toast.error(
            "Erro ao fazer a solicitação. Por favor, tente novamente."
          );
        } else {
          // Algo aconteceu na configuração do pedido que disparou um erro
          toast.error("Erro desconhecido. Por favor, tente novamente.");
        }
      }
    };

    fetchArtist();
    // eslint-disable-next-line
  }, []);

  const addFacebookPixel = (pixelid) => {
    // Verificar se o Pixel já está carregado
    if (window.fbq && window.fbq.queue) {
      console.warn("Meta Pixel já inicializado.");
      return;
    }
  
    // Iniciar o Pixel
    window.fbq = window.fbq || function () {
      window.fbq.callMethod
        ? window.fbq.callMethod(...arguments)
        : window.fbq.queue.push(...arguments);
    };
    window.fbq.version = "2.0";
    window.fbq.queue = [];
  
    // Adicionar script dinamicamente
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://connect.facebook.net/en_US/fbevents.js";
    script.setAttribute("data-pixel-id", pixelid);
  
    script.onload = () => {
      // Configurar o Pixel com o ID
      window.fbq("init", pixelid);
      window.fbq("track", "PageView");
  
      // Confirmar no console
      console.log(`Meta Pixel carregado com sucesso!`);
    };
  
    script.onerror = () => {
      console.error("Erro ao carregar o script do Meta Pixel.");
    };
  
    document.head.appendChild(script);
  };
  
  const addNoScriptPixel = (pixelid) => {
    const noScript = document.createElement("noscript");
    noScript.innerHTML = `
      <img height="1" width="1" style="display:none"
           src="https://www.facebook.com/tr?id=${pixelid}&ev=PageView&noscript=1" />
    `;
    document.body.appendChild(noScript);
  };
    
    


  const handleStartClick = () => {
    resetForm();
    updateForm({ ...formState, artistid: artist._id });
    navigate("./contact");
  };

  return (
    <div className="saudao">
      <div className="container">
        {artist && (
          <>
            <img
              className="rectangle"
              alt="Rectangle"
              src={formState?.artista?.fotocapa ? formState?.artista?.fotocapa : "/img/form/rectangle-1.png"}
            />
            <div className="gradient-overlay"></div>
          </>
        )}

        <div className="frame">
          {artist && (
            <img
              className="ellipse"
              alt="Ellipse"
              src={formState?.artista?.fotoperfil}
            />
          )}
        </div>
        {artist && (
          <div className="opa-tudo-certo-aqui-wrapper">
            <p className="opa-tudo-certo-aqui">
              {artist.text1 ? artist.text1 : mensagemPadrao}
            </p>
          </div>
        )}
        {!artist && (
          <div className="opa-tudo-certo-aqui-wrapper">
            <p className="opa-tudo-certo-aqui">Carregando Artista</p>
          </div>
        )}
      </div>
      {artist && (
        <div
        className="div-wrapper"
        onClick={() => {
          window.fbq && window.fbq("track", "Lead", {
            content_name: "Iniciou Formulário!"
          });
          handleStartClick();
        }}
      >
        <div className="text-wrapper">Vamos!</div>
      </div>
      
      )}
    </div>
  );
}

export default Form;
