import React, { useEffect, useState, useContext } from "react";
import "./ViewArtist.css";
// import { logout } from "../../api/api";
import { differenceInDays, startOfDay } from "date-fns";
import {
  getForms,
  getFullForm,
  updateOneForm,
  logout,
  checkToken,
  getArtist,
  updatePassword
} from "../../api/api";
import { FormContext } from "./Form/FormContext";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Modal from "../components/Modal/Modal";
import EyeFill from "../SVG/EyeFill";
import EyeOffFill from "../SVG/EyeOffFill";

function ViewArtist() {
  let userNew = JSON.parse(localStorage.getItem("user"));
  const [user, setUser] = useState(userNew);
  const [filter, setFilter] = React.useState({ answered: false });
  const [forms, setForms] = React.useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const { updateForm, resetForm } = useContext(FormContext);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchToken = async () => {
      try {
        await checkToken();
      } catch (error) {
        console.error("Erro ao validar o token", error);
        if (error.response) {
          // O pedido foi feito e o servidor respondeu com um status fora do intervalo de 2xx
          toast.error(error.response.data.msg);
        } else if (error.request) {
          // O pedido foi feito, mas nenhuma resposta foi recebida
          toast.error("Erro ao fazer o pedido. Por favor, tente novamente.");
        } else {
          // Algo aconteceu na configuração do pedido que disparou um erro
          toast.error("Erro desconhecido. Por favor, tente novamente.");
        }
        logout();
      }
    };
    fetchToken();
  }, []);

  useEffect(() => {
    const fetchArtist = async () => {
      if (user) {
        const resultado = await getArtist(userNew._id);
        setUser(resultado);
        // Adicionar o Pixel do Facebook
      }
    };
    fetchArtist();
    // eslint-disable-next-line
  }, []);

  

  

  useEffect(() => {
    const fetchForms = async () => {
      const forms = await getForms({ ...filter, artistid: user._id });
      setForms(forms);
    };
    fetchForms();
    // eslint-disable-next-line
  }, [filter]);

  const [isAnyFormUnopened, setIsAnyFormUnopened] = useState(false);

  useEffect(() => {
    const unopenedFormExists = forms.some((form) => !form.opened);
    setIsAnyFormUnopened(unopenedFormExists);
  }, [forms]);

  const handleFilter = (answered) => {
    setFilter({ ...filter, answered });
  };

  const handleCopy = () => {
    const textToCopy = `app.tattool.com.br/${user.alias}`;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        toast.success("Copiado");
      })
      .catch((err) => {
        toast.success("Erro");
        console.error("Erro ao copiar texto: ", err);
      });
  };

  const handleOnClick = async (form) => {
    const formFull = await getFullForm(form);
    const newForm = { _id: formFull._id, data: { opened: true } };
    await updateOneForm(newForm);
    resetForm();
    updateForm(formFull);
    navigate("../form");
  };

  useEffect(() => {
    if (user.firstlogin) {
      setModalVisible(true);
    }
  }, [user]);

  const handleSubmit = async (event) => {
    if(password && password === confirmpassword){
      await updatePassword(user._id, password)
      setConfirmPassword(null)
      setPassword(null)
      setModalVisible(false);
    }
  };

  return (
    <div className="home-recebidos">
      <div className="frame">
        <div className="container">
          <header className="header">
            <div className="div">
              <img className="ellipse" alt="Ellipse" src={user.fotoperfil} />
              <div className="frame-2">
                <div className="text-wrapper">{user.nickname}</div>
                <div className="frame-3">
                  <div className="text-wrapper-2">{`app.tattool.com.br/${user.alias}`}</div>
                  <img
                    className="img"
                    alt="Content copy"
                    src="/img/formartist/content-copy.png"
                    onClick={handleCopy}
                  />
                </div>
                <div className="text-wrapper" onClick={() => logout()}>
                  sair
                </div>
              </div>
            </div>

            <div className="text-wrapper-3">Meus orçamentos</div>
          </header>
          <div className="filters">
            <div
              className={`filter-${filter.answered ? "unchecked" : "checked"}`}
              onClick={() => handleFilter(false)}
            >
              <img
                className="img"
                alt="Call received"
                src={`/img/formartist/call-received-${
                  filter.answered ? "unchecked" : "checked"
                }.png`}
              />
              <div
                className={`text-wrapper-${
                  filter.answered ? "unchecked" : "checked"
                }`}
              >
                Recebidos
              </div>
              {isAnyFormUnopened ? <div className="ellipse-2" /> : null}
            </div>
            <div
              className={`filter-${!filter.answered ? "unchecked" : "checked"}`}
              onClick={() => handleFilter(true)}
            >
              <img
                className="img"
                alt="Call made"
                src={`/img/formartist/call-made-${
                  !filter.answered ? "unchecked" : "checked"
                }.png`}
              />
              <div
                className={`text-wrapper-${
                  !filter.answered ? "unchecked" : "checked"
                }`}
              >
                Respondidos
              </div>
            </div>
          </div>
          {forms.length === 0 ? (
            <div className="frame-empty">
              <div className="mark-chat-read-wrapper">
                <img
                  className="mark-chat-read"
                  alt="Mark chat read"
                  src="/img/formartist/mark-chat-read.png"
                />
              </div>
              <div className="nenhum-or-amento">
                {filter.answered ? (
                  <>
                    Os orçamentos respondidos <br />
                    irão aparecer aqui
                  </>
                ) : (
                  <>
                    Nenhum orçamento <br />
                    para responder
                  </>
                )}
              </div>
            </div>
          ) : (
            <div className="frame-4">
              {forms.map((form) => (
                <div key={form._id}>
                  <div className="item" onClick={() => handleOnClick(form)}>
                    <div className="account-circle-wrapper">
                      <img
                        className="account-circle"
                        alt="Account circle"
                        src="/img/formartist/account-circle-5.png"
                      />
                    </div>
                    <div className="frame-5">
                      <div className="text-wrapper-6">
                        {differenceInDays(
                          startOfDay(new Date()),
                          startOfDay(new Date(form.date))
                        ) === 0
                          ? "Hoje"
                          : `${differenceInDays(
                              startOfDay(new Date()),
                              startOfDay(new Date(form.date))
                            )} dias`}
                      </div>
                      <div className="text-wrapper-7">{form.form.name}</div>
                    </div>
                    {!form.opened ? (
                      <div className="ellipse-2 answered" />
                    ) : null}
                    <img
                      className="chevron-right"
                      alt="Chevron right"
                      src="/img/formartist/chevron-right-5.png"
                    />
                  </div>
                  <img
                    className="line"
                    alt="Line"
                    src="/img/formartist/line-7.svg"
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      {modalVisible && (
        <Modal show={modalVisible} onClose={() => setModalVisible(false)}>
          
            <>
              Olá, bem vindo ao Tattool! <br />
              <br />
              Percebemos que você está usando sua senha temporária, para maior segurança altere sua senha para os próximos acessos{" "}
              <br />
              <br />
            </>
            <div className="frame-3">
              <div className="div-wrapper">
                <input
                  className="text-wrapper-2"
                  placeholder="Digite sua senha"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                  }}
                >
                  {showPassword ? <EyeFill /> : <EyeOffFill />}{" "}
                  {/* Troque por um ícone de biblioteca se necessário */}
                </button>
              </div>
            </div>

            <br />

            <div className="frame-3">
              <div className="div-wrapper">
                <input
                  className="text-wrapper-2"
                  placeholder="Confirme sua senha"
                  type={showPassword ? "text" : "password"}
                  value={confirmpassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                  }}
                >
                  {showPassword ? <EyeFill /> : <EyeOffFill />}{" "}
                  {/* Troque por um ícone de biblioteca se necessário */}
                </button>
              </div>
            </div>
            <br />
            <button type="submit" className={`frame-bt-${password && password === confirmpassword ? 'enabled': 'disabled'}`} onClick={handleSubmit}>
              <div className={`text-wrapper-bt-${password && password === confirmpassword ? 'enabled': 'disabled'}`}>Alterar</div>
            </button>
          
        </Modal>
      )}
    </div>
  );
}

export default ViewArtist;
